<template>
    <div class="net-value">
        <el-card>
            <div slot="header" class="clearfix">
                <span>历史权益</span>
                <date-range style="float: right" v-on:change="getData"></date-range>
            </div>
            <h-chart ref="chart" id="chart" :options="options"></h-chart>
            <div class="blackboard">
                <p class="star-level"><span class="label">指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p><span class="label">指标释义：</span>反映该账户的每日权益信息</p>
                <p><span class="label">如何使用：</span>从当日权益图走势，可以直观了解投资人的账户权益情况，帮助投资人更好的了解账户权益规模变动。</p>
            </div>
        </el-card>
        <el-dialog title="笔记" :visible.sync="notesDialogVisible" width="800px" class="notes-dialog"
                   :close-on-click-modal="false">
            <el-form :model="notesForm" ref="notesForm" label-position="top">
                <!--<el-form-item label="日期" label-width="80px" prop="trade_date">
                    <el-input :disabled="true" v-model="notesForm.trade_date" autocomplete="off"></el-input>
                </el-form-item>-->
                <quill-editor ref="content" v-model="notesForm.content" :options="editorOption"/>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="notesDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveNotes()">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
  import HChart from '@/components/HChart.vue';
  import DateRange from '@/components/DateRange.vue';
  import {Loading} from 'element-ui';
  import {apiGetNotes, apiSaveNotes} from "../../utils/api";
  import {quillEditor} from 'vue-quill-editor';

  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import 'quill/dist/quill.bubble.css';
  import {apiAnalysisData} from "../../api/analysis";

  export default {
    name: "Equities",
    data () {
      return {
        starLevel: 4,
        options: {
          credits: {
            enabled: false
          },
          title: '',
          chart: {
            zoomType: 'x',
            resetZoomButton: {
              // relativeTo: 'chart',
              position: {
                // align: 'right', // by default
                // verticalAlign: 'top', // by default
                x: 0,
                y: -35
              }
            },
            spacingTop: 35,
            backgroundColor: null,
            plotBackgroundColor: null
            // backgroundColor: '#ededed',
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              events: {
                click: (event) => this.openNotesDialog(event)
              }
            }
          },
          colors: [
            '#EB3E41', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80',
            '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa',
            '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050',
            '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'
          ],
          xAxis: {
            // tickInterval: 20,
            categories: []
          },
          yAxis: {
            title: false,
          },
          series: [],
        },
        notesDialogVisible: false,
        notesForm: {
          id: '',
          trade_date: '',
          content: '',
          type: 'equities',
          account_id: ''
        },
        editorOption: {
          placeholder: '在此输入内容...'
        }
      };
    },
    components: {
      HChart,
      DateRange,
      quillEditor
    },
    methods: {
      openNotesDialog (event) {
        let account_id = this.$store.state.curAccountId[0];
        let trade_date = event.point.category;
        this.notesForm.account_id = account_id;
        this.notesForm.trade_date = trade_date;

        apiGetNotes({'account_id': account_id, 'trade_date': trade_date, 'type': 'netValue'}).then(response => {
          if (response.data.code === 0) {
            this.notesForm.id = response.data.data.id;
            this.notesForm.content = response.data.data.content;
          }
          this.notesDialogVisible = true;
        });
      },
      saveNotes () {
        apiSaveNotes(this.notesForm).then(response => {
          if (response.data.code === 0) {
            this.$message({
              'message': response.data.msg,
              'type': 'success'
            });
            this.notesDialogVisible = false;
          } else {
            this.$message({
              'message': response.data.msg,
              'type': 'error'
            });
          }
        });
      },
      async getData () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        let loadingInstance = Loading.service({target: '.h-chart'});
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date
        }
        let res = await apiAnalysisData('equities', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          let categories = []
          let f1s = []
          Array.isArray(resData) && resData.map(item => {
            if (item.date) {
              categories.push(item.date)
            }
            if (item.f1 || item.f1 === 0) {
              f1s.push(item.f1)
            }
          })
          this.options.xAxis.categories = categories;
          this.options.series = [{
            name: '历史权益',
            data: f1s
          }];
          this.$refs.chart.redraw();
          loadingInstance.close();
        }
      }
    },
    mounted () {
      this.getData();
    }
  }
</script>

<style lang="scss">
    .net-value {
        .el-card {
            border: 0;

            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

            .chart {
                margin-bottom: 20px;
                background-image: url("../../assets/watermark.png");
                background-repeat: no-repeat;
                background-size: 269px 150px;
                background-position: center;
            }
        }

        .notes-dialog {
            .el-form-item__content {
                line-height: normal;
            }

            .quill-editor {
                .ql-editor {
                    min-height: 300px;
                }
            }
        }
    }
</style>
